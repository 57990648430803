import { Action } from '@ngrx/store';
import { UserModel } from 'src/app/core/_models/user.model';
import { CardModel } from '../../_models/cards.model';


export enum UserActionTypes {
  UserRequested = '[Request User] Action',
  UserLoaded = '[Load User] Auth API',
  UserCardsRequested = '[Request User Cards] Action',
  UserCardsLoaded = '[Load User Cards] API',
  DeleteCardRequested = '[Request Delete Card ] Action',
}

export class UserRequested implements Action {
  readonly type = UserActionTypes.UserRequested;
}

export class UserLoaded implements Action {
  readonly type = UserActionTypes.UserLoaded;
  constructor(public payload: { user: UserModel }) { }
}

export class UserCardsRequested implements Action {
  readonly type = UserActionTypes.UserCardsRequested;
  constructor(public payload: { provider: string }) { }
}

export class UserCardsLoaded implements Action {
  readonly type = UserActionTypes.UserCardsLoaded;
  constructor(public payload: { cards: CardModel[] }) { }
}

export class DeleteCardRequested implements Action {
  readonly type = UserActionTypes.DeleteCardRequested;
  constructor(public payload:{card_id: string}) { }
}



export type UserActions =  
UserRequested | 
UserLoaded | 
UserCardsRequested | 
UserCardsLoaded | 
DeleteCardRequested;
