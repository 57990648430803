import { Action } from '@ngrx/store';
import { SurveyModel } from 'src/app/core/_models/survey.model';

export enum SurveyActionTypes {
  SurveyRequested = '[Request Survey] Action',
  SurveyLoaded = '[Load Survey] Action',
  SurveyRequestedCanceled = '[Request Canceled Survey] Action',
  SurveyRequestedOmitted = '[Request Omitted Survey] Action',
  SurveyRequestedAsnwer = '[Request Asnwer Survey] Action',
}

export class SurveyRequested implements Action {
  readonly type = SurveyActionTypes.SurveyRequested;
  constructor(public payload: { site_id: string }) { }
}

export class SurveyLoaded implements Action {
  readonly type = SurveyActionTypes.SurveyLoaded;
  constructor(public payload: { survey: SurveyModel | null }) { }
}

export class SurveyRequestedCanceled implements Action {
  readonly type = SurveyActionTypes.SurveyRequestedCanceled;
  constructor(public payload: { survey_id: string }) { }
}

export class SurveyRequestedOmitted implements Action {
  readonly type = SurveyActionTypes.SurveyRequestedOmitted;
  constructor(public payload: { survey_id: string }) { }
}

export class SurveyRequestedAsnwer implements Action {
  readonly type = SurveyActionTypes.SurveyRequestedAsnwer;
  constructor(public payload: { survey: SurveyModel }) { }
}

export type SurveyActions =
  SurveyRequested |
  SurveyLoaded |
  SurveyRequestedCanceled |
  SurveyRequestedOmitted |
  SurveyRequestedAsnwer
  ;
