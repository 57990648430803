import { Component } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { routerAnimationOne } from './core/_animations/router-animations.contants';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { LayoutUtilsServices, MessageType } from './core/_services/layout-utils.services';
import { checkConnectivityService } from './core/_services/check-connectivity.services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, combineLatest, take } from 'rxjs';

@Component({
  selector: 'forrest-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerAnimationOne]
})
export class AppComponent {
  title = 'Wibo';
  checkConnectivity: boolean = false
  snackbarClose: any | null = null;

  private subscriptions: Subscription[] = []
  constructor(
    private router: Router,
    private _translateService: TranslateService,
    private titleService: Title,
    private _layoutUtilsService: LayoutUtilsServices,
    private snackBar: MatSnackBar,
    private conexionService: checkConnectivityService
  ) {
    const language = localStorage.getItem('language');
    this._translateService.use(language || this._translateService.getBrowserLang());
    // this.translateTitles();

 
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        const urlTree = this.router.parseUrl(event.url);

        if (urlTree.queryParamMap.keys.length === 0) {
          window.scrollTo(0, 0);
        }

        let route = this.router.routerState.root.snapshot;
        let title = this.getTitle(route);

        if (title) {
          const translatedTitle = this._translateService.instant(title);
          setTimeout(() => {
            this.titleService.setTitle(translatedTitle);
            document.title = translatedTitle
          }, 50)
        }

      }

    });

    this.subscriptions.push(
      combineLatest([
        this.conexionService.isConnected$,
        this.conexionService.speed$
      ]).subscribe(([isConnected, speed]) => {
        let valid = true;
        let messages = 'Parece que tu conexión está lenta.'
        if(speed== '2g' || !isConnected){
          valid = false
        }
        if (!valid && !this.snackbarClose) {
          this.snackbarClose =  this._layoutUtilsService.showNotification(
            messages, MessageType.Warning, 30000, true, true, 10000, "top")
          this.snackbarAfterDismissed(this.snackbarClose)
         
          }
        if(valid && this.snackbarClose){
          this.snackBar.dismiss();
        }
      }));
  }


  snackbarAfterDismissed (snackBarRef: any ){
    snackBarRef?.afterDismissed()?.subscribe((res : any) => {
      this.snackbarClose = null;
    });

  }

  getAnimationState(outlet: RouterOutlet) {
    return outlet?.isActivated ? outlet?.activatedRoute : { toString: () => '' };
  }


  getTitle(routeSnapshot: ActivatedRouteSnapshot): string | null | any {
    if (routeSnapshot.firstChild) {
      return this.getTitle(routeSnapshot.firstChild);
    }
    if (routeSnapshot.data['title']) {
      return routeSnapshot.data['title'];
    }
    return routeSnapshot.routeConfig && routeSnapshot.routeConfig['title'] ? routeSnapshot.routeConfig['title'] : null;
  }
}
