<h1 mat-dialog-title class="text-center">Borrar Tarjeta</h1>
<div mat-dialog-content>
  ¿Confirma que desea borrar esta tarjeta?
</div>
<div mat-dialog-actions>

      <div class="w-100 text-center d-flex justify-content-between mb-3 mx-3">
        <forrest-btn-rounded-xl mat-dialog-close cdkFocusInitial [title]="'Cancelar'"
      [color]="'btn-white'"></forrest-btn-rounded-xl>
      <forrest-btn-rounded-xl mat-dialog-close (click)="deleteCard()" [title]="'Confirmar'"
      [color]="'btn-dark'"></forrest-btn-rounded-xl>
      </div>
</div>
