
  <div class=" container-inapp  d-flex flex-column container-white">

    <div class="title px-3 d-flex justify-content-center align-items-center my-3">
      <span class="  text-center">{{data.title}}</span>
    </div>

    <div class="content d-flex justify-content-center">
      <span class="px-3 text-center">{{data.message}}</span>
    </div>

    <div class="actions m-3 ">
      <div class="d-flex  justify-content-center align-items-center gap-default">

          <forrest-btn-rounded-xl class="w-50"  mat-dialog-close cdkFocusInitial [title]="data.btnTextDeny"
            [color]="'btn-white'">
          </forrest-btn-rounded-xl>

          <forrest-btn-rounded-xl class="w-50" mat-dialog-close (click)="handleConfirm()" [title]="data.btnTextConfirm"
            [color]="'btn-dark'">
          </forrest-btn-rounded-xl>

      </div>
    </div>

  </div>

