import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http.services';
import { InappModel } from '../_models/inapp.model';

@Injectable({ providedIn: 'root' })
export class InAppService {

  constructor(
    private httpx: HttpService,
  ) { }

  getInAppActive(query_params={}): Observable<InappModel> {
    return this.httpx.get('inapp', {}, query_params)
      .pipe(
        map((res) => new InappModel(res)));
  }

  putReadInApp(communication_id: string, user_id: string): any {
    return this.httpx.put('inapp_read', {}, {'communication_id': communication_id, 'user_id': user_id})
      .pipe(
        map((res) => console.log(res)));
  }

}
