import { trigger, transition, style, animate, query, animateChild, group } from '@angular/animations';

export const routerAnimationOne = trigger('routeAnimation', [
  transition('* <=> *', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        opacity: 0,
        transform: 'scale(0.9)'
      })
    ], { optional: true }),
    query(':enter', [
      style({ opacity: 0 })
    ], { optional: true }),
    query(':leave', [
      animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 0, transform: 'scale(0.9)' }))
    ], { optional: true }),
    query(':enter', [
      animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 1, transform: 'scale(1)' }))
    ], { optional: true }),
    query(':enter', [
      animate('0ms', style({ transform: 'none' })) // Agregar esta línea
    ], { optional: true, delay: '200ms' })
  ]),
  transition('* <=> void', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        opacity: 0,
        transform: 'scale(0.9)'
      })
    ], { optional: true }),
    query(':leave', [
      animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 0, transform: 'scale(0.9)' }))
    ], { optional: true }),
    query(':enter', [
      animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 1, transform: 'scale(1)' }))
    ], { optional: true })
  ])
])

export const routerAnimationTwo = trigger('routeAnimation', [
  transition('* <=> *', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        opacity: 0,
        transform: 'scale(0.9)'
      })
    ]),
    query(':enter', [
      style({ opacity: 0 })
    ]),
    query(':leave', [
      animate('300ms ease-out', style({ opacity: 0, transform: 'scale(0.9)' }))
    ]),
    query(':enter', [
      animate('300ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
    ])
  ]),
  transition('* <=> void', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        opacity: 0,
        transform: 'scale(0.9)'
      })
    ]),
    query(':leave', [
      animate('300ms ease-out', style({ opacity: 0, transform: 'scale(0.9)' }))
    ]),
    query(':enter', [
      animate('300ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
    ])
  ])
])

export const routerAnimationThree = trigger('routeAnimation', [
  transition('* <=> *', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        opacity: 0
      })
    ], { optional: true }),
    query(':enter', [
      style({ opacity: 0 })
    ], { optional: true }),
    query(':leave', [
      animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 0 }))
    ], { optional: true }),
    query(':enter', [
      animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 1}))
    ], { optional: true }),
    query(':enter', [
      animate('0ms', style({  })) // Agregar esta línea
    ], { optional: true, delay: '200ms' })
  ]),
  transition('* <=> void', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        opacity: 0,
        transform: 'scale(0.9)'
      })
    ], { optional: true }),
    query(':leave', [
      animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 0 }))
    ], { optional: true }),
    query(':enter', [
      animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 1 }))
    ], { optional: true })
  ])
])
