import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-modal-status-order',
  templateUrl: './modal-status-order.component.html',
  styleUrls: ['./modal-status-order.component.scss']
})
export class ModalStatusOrderComponent implements OnInit {

  animationSuccess?: AnimationItem;
  animationFailed?: AnimationItem;

  optionsAnimatedSuccess: AnimationOptions = {
    path: '/assets/successful-animation.json',
  };
  optionsAnimatedFailed: AnimationOptions = {
    path: '/assets/payment-failed.json',
  };

  constructor(
    public dialogRef: MatDialogRef<ModalStatusOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
   ){}

   animationCreatedSuccess(animationItem: AnimationItem): void {
    animationItem.playSpeed = 1
    animationItem.setDirection(1)
    animationItem.autoplay = true;
    animationItem.loop= true
    this.animationSuccess = animationItem;
  }
  animationCreatedFailed(animationItem: AnimationItem): void {
    animationItem.playSpeed = 1
    animationItem.setDirection(1)
    animationItem.autoplay = true;
    animationItem.loop= true
    this.animationFailed = animationItem;
  }

   ngOnInit(): void {



   }
   ngAfterViewInit(){
    if(this.data){
      this.playAnimationSuccess()
    }else{
     this.playAnimationFailed()
    }
   }
   

   playAnimationSuccess(): void {
    if (this.animationSuccess) {
      this.animationSuccess.play()
      this.animationSuccess.setDirection(1)

    }
    
  }
    playAnimationFailed(): void {
      if (this.animationFailed) {
        this.animationFailed.play()
        this.animationFailed.setDirection(1)
      }

    }
   

  
}
