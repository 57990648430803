import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
        tap((event) =>{
            if(event.type === HttpEventType.Response) {
                let session_id = event.headers.get('x-wibo-session-id-x');
                if (session_id!==null && session_id!==undefined) {
                    localStorage.setItem('session-id', session_id);
                }
            }
        })
    )
  }
}
