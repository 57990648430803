<div class="mat-snackbar p-3 d-flex justify-content-between " [ngClass]="{'color-success': data.type == 0, 'color-warning': data.type == 1, 'bg-information': data.type == 2, 'color-danger': data.type == 3 }">
  <div class="d-flex justify-content-start">
    <div class="mat-snackbar-message ">
      <span [ngClass]="{'svg-icon-white': data.type == 0 ||data.type == 3 , 'svg-icon-danger': data.type == 1}"  class="svg-icon ">
        <svg-icon src="/assets/icons/svg/{{data.type | iconSnackbar}}.svg"></svg-icon>
      </span>
      
    </div>
    <span class="ps-2 align-self-center" > {{data.message | translate}}</span>
  </div>
  
  <div class="mat-snackbar-close ">
    <button class="btn-icon-svg" (click)="onDismissWithAction()" >
      <span [ngClass]="{'svg-icon-white': data.type == 0 ||data.type == 3 , 'svg-icon-danger': data.type == 1}" class="svg-icon">
        <svg-icon src="/assets/icons/svg/close.svg"></svg-icon>
      </span>
    </button>
  </div>
</div>
