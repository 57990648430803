<form class="d-flex flex-row justify-content-center " [formGroup]="form">
    <div class="container-inapp d-flex flex-column px-3">

        <button (click)="cancel()" class="d-flex justify-content-end pt-3" type="button">
            <span class="svg-icon svg-icon-black d-flex align-items-center">
                <svg-icon src="/assets/icons/svg/close.svg"></svg-icon>
            </span>
        </button>

        <div class=" d-flex justify-content-center mb-3" *ngIf="optionQualificationSelected?.value">
            <span class="emojins">{{optionQualificationSelected?.label}}</span>
        </div>

        <div class="title px-3 d-flex justify-content-center align-items-center mb-3">
            <span class=" text-center" *ngIf="!optionQualificationSelected?.value">
                ¿Cómo calificas tu experiencia de compra?
            </span>

            <span class=" text-center" *ngIf="optionQualificationSelected?.value">
                ¿Qué fue lo que más te gustó?
            </span>
        </div>

        <div class="content d-flex flex-column justify-content-center mb-3">

            <div class="d-flex options-qualifications" *ngIf="!optionQualificationSelected?.value">
                <button style="flex: 1; width: 100%;" (click)="selectOption(option)" class="btn"
                    *ngFor="let option of optionsQualifications">{{option.label}}</button>
            </div>

            <ng-container *ngIf="optionQualificationSelected && optionQualificationSelected?.value">


                <mat-button-toggle-group [multiple]="true" #group="matButtonToggleGroup"
                    (change)="changeInToggleGroup(group.value)">
                    <mat-button-toggle *ngFor="let tag of tags" [value]="tag">
                        {{tag.title}}
                    </mat-button-toggle>
                </mat-button-toggle-group>

                <div>
                    <div class="mb-2">
                        <span class="label-textarea">Dime más... </span>
                    </div>
                    <div class="mb-2">
                        <forrest-textarea [control]="form.get('comments')"
                            [placeholder]="'¡Queremos leer tus sugerencias! (opcional)'"></forrest-textarea>
                    </div>
                </div>

            </ng-container>

        </div>

        <div class="actions mb-3 row align-items-center">
            <div class="col-6">
                <button class="btn btn-dark rounded-pill w-100" [disabled]="!optionQualificationSelected?.value"
                    (click)="onSubmit()">Enviar</button>
            </div>
            <div class="col-6">
                <button (click)="omitted()" class="ignore w-100" type="button">Ignorar</button>
            </div>
        </div>
    </div>
</form>
