<div class="d-flex flex-row justify-content-center ">
    <div [ngClass]="{'container-white': data.inapp.subtype != 'image_non_background', 'container-transparent':  data.inapp.subtype == 'image_non_background'}"
        class=" container-inapp  d-flex flex-column ">

        <div *ngIf="data.inapp.emojis && data.inapp.subtype == 'non_image'" class=" d-flex justify-content-center pt-3">
            <span *ngFor="let emoji of data.inapp.emojis" class="emojins">{{emoji}}</span>
        </div>

        <div *ngIf="data.inapp.title && data.inapp.subtype == 'non_image' "
            class="title px-3 d-flex justify-content-center align-items-center my-3">
            <span class="  text-center">{{data.inapp.title}}</span>
        </div>

        <div class="d-flex justify-content-center" *ngIf="data.inapp.subtype != 'non_image'">
            <img class="w-100" *ngIf="data.inapp.subtype == 'image'" [src]="data.inapp.image.url" alt="imageInaap"
                style="object-fit: contain; border-top-right-radius: 20px; border-top-left-radius: 20px;">

            <img class="w-100" *ngIf="data.inapp.subtype == 'image_non_background'" [src]="data.inapp.image.url" alt="imageInaap"
                style="object-fit: contain;">
        </div>

        <div class="content d-flex justify-content-center"
            *ngIf="data.inapp.title && data.inapp.subtype == 'non_image'">
            <span *ngIf="data.inapp.content" class="px-3 text-center">{{data.inapp.content}}</span>
        </div>

        <div class="actions my-3">
            <div class="d-flex flex-column  justify-content-center align-items-center">
                <div *ngIf="data.inapp.redirect_title == '' || data.inapp.subtype == 'image_non_background'">
                    <button (click)="onNoClick()" class="button-close rounded-pill mb-3 px-3" type="button">
                        <span [class.svg-icon-gray-500]="data.inapp.subtype == 'non_image'"
                            [class.svg-icon-white]="data.inapp.subtype == 'image_non_background'"
                            class="svg-icon d-flex align-items-center">
                            <svg-icon src="/assets/icons/svg/close2.svg"></svg-icon>
                        </span>
                    </button>
                </div>

                <div *ngIf="['image','non_image'].includes(data.inapp.subtype)" class=" d-flex flex-column ">
                    <button (click)="goBack()" mat-button class="accept rounded-pill mb-3 px-3"
                        type="button">{{data.inapp.redirect_title}}</button>
                    <button (click)="onNoClick()" class="ignore" type="button">
                        {{data?.inapp?.close_title || 'No gracias'}}
                    </button>
                </div>
            </div>
        </div>


    </div>
</div>
