import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http.services';
import { UserModel } from '../_models/user.model';
import { environment } from '../../../environments/environment';


declare var window: any;

@Injectable({ providedIn: 'root' })
export class HubSpotService {
  constructor(
    private httpx: HttpService,
  ) { }

  init(active: boolean = true) {
    window.hsConversationsSettings = {
      loadImmediately: false,
      inlineEmbedSelector: '#some-id',
      enableWidgetCookieBanner: true,
      disableAttachment: true
    };

    const status = window?.HubSpotConversations?.widget?.status();
    // console.log(status)
    if (status?.loaded) {
      window?.HubSpotConversations?.widget?.refresh();
    } else {
      window?.HubSpotConversations?.widget?.load();
      if (!active) {
        // console.log('---')
        this.destroy()
      }
    }
  }

  destroy() {
    window?.HubSpotConversations?.widget?.remove({ resetWidget: true, pending: false });
  }

}
