import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'forrest-btn-rounded-xl',
  templateUrl: './btn-rounded-xl.component.html',
  styleUrls: ['./btn-rounded-xl.component.scss']
})
export class RoundedButtonXlComponent {
  @Input() title?: string
  @Input() color?: string = 'btn-dark'
  @Input() disabled: boolean = false
  @Input() btnFullWidth: boolean = true
  @Output() eventClick= new EventEmitter<boolean>();

  handleClick() {
    this.eventClick.emit(true)
  }
}
