import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Map } from '../_models/abstract-map.model';
import { DOCUMENT } from '@angular/common';


const WHITE_LIST = {
    list: ['wibodev.com', 'localhost', '127.0.0.1'],
    in: function(url: string) {
        for (let list of this.list) {
            if (url.indexOf(list) !== -1) {
                return true;
            }
        }
        return false;
    }
}


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(@Inject(DOCUMENT) private document: Document) {}
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
        ): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const windowDoc: any = this.document.defaultView
        let w_session_id: string | null = windowDoc.session_id;
        let ls_session_id: string | null = localStorage.getItem('session-id');
        let session_id = (ls_session_id !== null) ? ls_session_id :w_session_id;

        let token: string | null = localStorage.getItem(environment.auth_token_key);
        let user_encrypt: string | null = localStorage.getItem(environment.auth_token_key + '-encrypt-id');
        let headers: Map = {
            'x-wibo-site-x': windowDoc.site_encrypt,
            'x-wibo-session-id-x': session_id
        };

        if (user_encrypt !== undefined && user_encrypt !== null) {
            headers['x-wibo-user-x'] = user_encrypt
        }
        if (token !== undefined && token !== null) {
            headers['Authorization'] = 'Bearer '+ token;
        }
        if (WHITE_LIST.in(request.url)) {
            request = request.clone({
                    setHeaders: headers
                });
        }
        return next.handle(request);
    }
}
