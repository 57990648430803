import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http.services';
import { SurveyModel } from '../_models/survey.model';

@Injectable({ providedIn: 'root' })
export class SurveysService {
  constructor(
    private httpx: HttpService,
  ) { }

  getSurveyActive(query_params = {}): Observable<SurveyModel> {
    return this.httpx.get('surveys', {}, query_params)
      .pipe(
        map((res) => new SurveyModel(res.peding)));
  }

  answerSurvey(body: SurveyModel): any {
    return this.httpx.put('surveys_asnwer', body, { 'survey_id': body.id })
  }

  omittedSurvey(survey_id: string): any {
    return this.httpx.put('surveys_omitted', {}, { 'survey_id': survey_id })
  }

  canceledSurvey(survey_id: string): any {
    return this.httpx.put('surveys_canceled', {}, { 'survey_id': survey_id })
  }

  getTags(site_id: string) {
    return this.httpx.get('surveys_tags', {}, { site_id })
      .pipe(
        map((res) => res));
  }

}
