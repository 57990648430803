import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iconSnackbar'
})
export class IconSnackbarPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    switch(value){

      case 0:
        return 'check'

      case 1:
        return 'notification'
        case 3:
        return 'error'
      default:
        return ''
    }


  }

}