import { Component, Input, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { InappModel } from 'src/app/core/_models/inapp.model';
import { AppState } from 'src/app/core/_reducers';
import { InappReaded } from 'src/app/core/_reducers/_actions/inapp.actions';
import { currentInapp } from 'src/app/core/_reducers/_selectors/inapp.selector';
import { InAppService } from 'src/app/core/_services/inapp.services';

@Component({
  selector: 'forrest-element-inapp',
  templateUrl: './element-inapp.component.html',
  styleUrls: ['./element-inapp.component.scss']
})
export class ElementInappComponent implements OnInit{

  disabled: boolean = true
  
  private subscriptions: Subscription[] = []

  constructor(
    public dialogRef: MatDialogRef<ElementInappComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _inappServices: InAppService,
    private store: Store<AppState>,
    private router: Router
  ){}

  ngOnInit(): void {
    
  }

  onNoClick(): void {
    this.store.dispatch(new InappReaded({communication_id:this.data.inapp.id, user_id: this.data.user }))
    this.dialogRef.close();
    
  }

  goBack(){
    this.store.dispatch(new InappReaded({communication_id:this.data.inapp.id, user_id: this.data.user }))
    if(this.data.inapp.redirect_url){
      this.router.navigate([this.data.inapp.redirect_url]);
    }
    this.dialogRef.close();
    
    
  }
}
