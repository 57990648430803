import { Action } from '@ngrx/store';
import { InappModel } from '../../_models/inapp.model';

export enum InappActionTypes {
  InappRequested = '[Request Inapp] Action',
  InappLoaded = '[Load Inapp] Action',
  InappReaded = '[Read Inapp] Action'
}

export class InappRequested implements Action {
  readonly type = InappActionTypes.InappRequested;
  constructor(public payload: {
    site_id: string | null,
    user_id: string | undefined,
    store_id: string | undefined  }) { }
}

export class InappLoaded implements Action {
  readonly type = InappActionTypes.InappLoaded;
  constructor(public payload: { inapp: InappModel | null }) { }
}

export class InappReaded implements Action {
    readonly type = InappActionTypes.InappReaded;
    constructor(public payload: { communication_id: string, user_id: string }) { }
  }

export type InappActions =  InappRequested | InappLoaded | InappReaded;
