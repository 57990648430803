import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppState } from '../_reducers';
import { Store } from '@ngrx/store';
import { Logout } from 'src/app/modules/auth/_reducers/_actions/auth.actions';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<AppState>,
    private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401 || err.status === 403) {
        // auto logout if 401 response returned from api
        // this.store.dispatch(new Logout());
      }
      if (err.status === 500 || err.status === 0) {
        //this.router.navigate(['/error/500']);
      }
      if (err.status === 404) {
        //this.router.navigate(['404']);
      }

      return throwError(() => err);
    }))
  }
}
