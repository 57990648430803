export class CommunicationModel {
    id: string
    store_id: string
    site_id: string
    image: ImageUrl
    redirect_title: string
    title: string
    content: string
    readed: boolean
    type: string
    subtype: string
    close_title: string
    redirect_url: string
    created_at: string
    is_fixed: boolean

    constructor(obj: any = null) {
      this.id = obj?.id
      this.store_id = obj?.store_id
      this.site_id= obj?.site_id
      this.image= new ImageUrl(obj?.image)
      this.redirect_title= obj?.redirect_title
      this.title= obj?.title
      this.content= obj?.content
      this.readed= obj?.readed
      this.type = obj?.type
      this.subtype = obj?.subtype
      this.close_title = obj?.close_title
      this.redirect_url = obj?.redirect_url
      this.created_at = obj?.created_at
      this.is_fixed= obj?.is_fixed
    }
  }

  export class ImageUrl {
    url: string
    constructor(obj: any = null) {
      this.url = obj?.url
    }
  }
