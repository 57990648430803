import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'forrest-dialog-close-store',
  templateUrl: './dialog-close-store.component.html',
  styleUrls: ['./dialog-close-store.component.scss']
})
export class DialogCloseStoreComponent {
  constructor(public dialogRef: MatDialogRef<DialogCloseStoreComponent>) {}
}
