import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/_reducers';
import { OrderCancel } from 'src/app/core/_reducers/_actions/order.actions';

export interface DialogConfirmData {
  title: string;
  message: string;
  btnTextDeny: string;
  btnTextConfirm: string;
}
@Component({
  selector: 'app-cancel-confirmation',
  templateUrl: './cancel-confirmation.component.html',
  styleUrls: ['./cancel-confirmation.component.scss']
})
export class DialogCancelConfirmationComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogCancelConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmData,
    private store: Store<AppState>,
  ) { }

  handleConfirm() {
    this.dialogRef.close(true)
  }

}
