import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, merge, of, fromEvent, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class checkConnectivityService {
  private isConnectedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isConnected$: Observable<boolean> = this.isConnectedSubject.asObservable();

  private speedSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  speed$: Observable<string> = this.speedSubject.asObservable();



  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;
  constructor() {
    // this.verifyConnection(); // Inicia la verificación de conexión al crear el servicio

    // Configura una verificación periódica (cada 10 segundos, puedes ajustar esto)
    setInterval(() => {
      this.verifyConnection();
      this.verifyConnectionSpeed();
    }, 5000);
  }

  private verifyConnection() {

    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.networkStatus = status;
        this.updateConnectionStatus(status);
      });
  }
  

  private verifyConnectionSpeed() {

    const conn = (navigator as any).connection;
    if (conn) {
      if (conn.saveData) {
        // do something
      }
      const connectionlist = ["slow-2g", "2g", "3g", "4g"];
      const effectiveType = conn.effectiveType;
      this.updateSpeedConnection(effectiveType);
    }
  }

  private updateConnectionStatus(isConnected: boolean) {
    this.isConnectedSubject.next(isConnected);
  }

  private updateSpeedConnection(speed: string) {
    this.speedSubject.next(speed);
  }
}
