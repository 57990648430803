// Angular
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NoticationSnackBarComponent } from 'src/app/layout/notication-snack-bar/notication-snack-bar.component';
import { ElementModalSurveysComponent } from 'src/app/shared/element-modal-surveys/element-modal-surveys.component';
import { SurveyModel } from '../_models/survey.model';
import { DialogHelpComponent } from 'src/app/layout/dialog-help/dialog-help.component';
import { HubSpotService } from './hubspot.services';
import { UserModel } from '../_models/user.model';
import { InappModel } from '../_models/inapp.model';
import { ElementInappComponent } from 'src/app/shared/element-inapp/element-inapp.component';
import { ModalStatusOrderComponent } from 'src/app/shared/modal-status-order/modal-status-order.component';
import { DialogCloseStoreComponent } from 'src/app/shared/dialog-close-store/dialog-close-store.component';
import { DialogDeleteConfirmationComponent } from 'src/app/shared/dialog-delete-confirmation/dialog-delete-confirmation.component';
import { DialogCancelConfirmationComponent, DialogConfirmData } from 'src/app/shared/dialog-cancel-confirmation/cancel-confirmation.component';

export enum MessageType {
  Sussess,
  Warning,
  Information,
  Dangaer
}

@Injectable({
  providedIn: 'root'
})
export class LayoutUtilsServices {

  dialogInapp!: any | null
  dialogCloseStore!: any | null
  dialogDelete!: any | null
  dialogCancel!: any | null

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  showNotification(
    message: string,
    type: MessageType = MessageType.Information,
    duration: number = 2000,
    showCloseButton: boolean = true,
    showUndoButton: boolean = true,
    undoButtonDuration: number = 10000,
    verticalPosition: 'top' | 'bottom' = 'bottom',
    horizontalPosition: 'left' | 'center' | 'right' = 'center'
  ) {
    const data = {
      message,
      snackBar: this.snackBar,
      showCloseButton,
      showUndoButton,
      undoButtonDuration,
      verticalPosition,
      horizontalPosition,
      type,
      action: 'Undo'
    };
    return this.snackBar.openFromComponent(NoticationSnackBarComponent, {
      duration,
      data,
      verticalPosition,
      horizontalPosition
    });
  }



  openModalSurvey(data: SurveyModel) {
    return this.dialog.open(ElementModalSurveysComponent, {
      data: data,
      panelClass: 'modal-surverys',
      disableClose: true,
    })
  }

  openDialogHelp() {
    return this.dialog.open(DialogHelpComponent, {
      panelClass: 'dialog-help',
      // disableClose: true,
    })
  }


  openDialogInapp(user: UserModel | null, inapp: InappModel) {

    if (!this.dialogInapp) {
      this.dialogInapp = this.dialog.open(ElementInappComponent, {
        data: {
          user: user?.id,
          inapp: inapp,
        },
        disableClose: true,
        panelClass: 'modal-inapp'
      })
      this.dialogInapp?.afterClosed().subscribe((res: any) => {
        this.dialogInapp = null
      });
    }
    return null
  }

  openDialogCloseStore(): void {
    if (!this.dialogCloseStore) {
      this.dialogCloseStore = this.dialog.open(DialogCloseStoreComponent, {
        width: '350px',
        panelClass: 'dialog-close-store',
      });
      this.dialogCloseStore?.afterClosed().subscribe((res: any) => {
        this.dialogCloseStore = null
      });
    }

  }

  openDialogDeleteConfirmation(card_id: String): void {
    if (!this.dialogDelete) {
      this.dialogDelete = this.dialog.open(DialogDeleteConfirmationComponent, {
        data: {
          cardId: card_id,
        },
        width: '360px',
        panelClass: 'dialog-delete-confirmation',
      });
      this.dialogDelete?.afterClosed().subscribe((res: any) => {
        this.dialogDelete = null
      });
    }

  }

  openDialogCancelConfirmation(data: DialogConfirmData ) {
    return this.dialog.open(DialogCancelConfirmationComponent, {
      data: data,
      width: '360px',
      panelClass: 'modal-inapp'
    });


  }

  openAnimationStatusOrder(success: boolean | undefined) {
    return this.dialog.open(ModalStatusOrderComponent, {
      data: success,
      panelClass: 'modal-status-order'
    });
  }

}
