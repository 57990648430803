<!-- <h1 mat-dialog-title class="text-center">
  El comercio se encuentra cerrado 😢
</h1>
<div class="text-center" mat-dialog-content>
  El comercio no está recibiendo pedidos en este momento, puedes ver la oferta, pero no podrás ordenar aún
</div>
<div class="w-100 text-center mb-3">
  <forrest-btn-rounded-xl mat-dialog-close cdkFocusInitial [title]="'Ver oferta'"
    [color]="'btn-dark'"></forrest-btn-rounded-xl>
</div> -->

<div class="p-4">
  <h3 class="text-center mb-3 title">
    El comercio se encuentra cerrado 😢
  </h3>
  <p class="text-center mb-4 content">
    El comercio no está recibiendo pedidos en este momento, puedes ver la oferta, pero no podrás ordenar aún
  </p>
  <div class="text-center">
    <forrest-btn-rounded-xl [btnFullWidth]="false" mat-dialog-close cdkFocusInitial [title]="'Ver oferta'"
      [color]="'btn-dark'"></forrest-btn-rounded-xl>
  </div>
</div>
