import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SurveyModel } from 'src/app/core/_models/survey.model';
import { AppState } from 'src/app/core/_reducers';
import { SurveyRequestedAsnwer, SurveyRequestedCanceled, SurveyRequestedOmitted } from 'src/app/core/_reducers/_actions/survey.actions';
import { SurveysService } from 'src/app/core/_services/surveys.services';

@Component({
  selector: 'forrest-element-modal-surveys',
  templateUrl: './element-modal-surveys.component.html',
  styleUrls: ['./element-modal-surveys.component.scss']
})
export class ElementModalSurveysComponent implements OnInit {

  form: FormGroup = new FormGroup({})

  optionsQualifications: any = [
    {
      value: 1,
      label: '😡'
    }, {
      value: 2,
      label: '☹️'
    }, {
      value: 3,
      label: '😊'
    }, {
      value: 4,
      label: '😄'
    }, {
      value: 5,
      label: '😍'
    }]

  tags: any[] = []

  title: string = "¿Cómo calificas tu experiencia de compra?"
  optionQualificationSelected: any = null

  qualification: number = 0
  commits?: string
  dataSurveys?: {}

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private _surveysServices: SurveysService,
    @Inject(MAT_DIALOG_DATA) public data: SurveyModel,
    public dialogRef: MatDialogRef<ElementModalSurveysComponent>,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.loadData()
  }

  loadData() {
    this._surveysServices.getTags(this.data.site_id).subscribe(res => {
      this.tags = res
    })
  }

  createForm() {
    this.form = this.fb.group({
      comments: new FormControl(''),
      tags: [null]
    });
  }

  changeInToggleGroup(val: any) {
    console.log(val)
    this.form.get('tags')?.setValue(val)
  }

  cancel() {
    this.store.dispatch(new SurveyRequestedCanceled({ survey_id: this.data.id }))
    this.dialogRef.close();
  }

  omitted(): void {
    this.store.dispatch(new SurveyRequestedOmitted({ survey_id: this.data.id }))
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.invalid || !(this.optionQualificationSelected?.value)) {
      return
    }
    this.store.dispatch(new SurveyRequestedAsnwer({ survey: this.prepareData() }))
    this.dialogRef.close();
  }

  selectOption(option: any) {
    this.optionQualificationSelected = option
  }

  prepareData(): SurveyModel {
    let data = new SurveyModel(this.data)
    data.comments = this.form.get('comments')?.value
    data.tags = this.form.get('tags')?.value
    data.qualification = this.optionQualificationSelected.value
    return data
  }
}
