import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HubSpotService } from 'src/app/core/_services/hubspot.services';

declare var window: any;

@Component({
  selector: 'forrest-dialog-help',
  templateUrl: './dialog-help.component.html',
  styleUrls: ['./dialog-help.component.scss']
})
export class DialogHelpComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogHelpComponent>,
    private _hubSpotService: HubSpotService
  ) { }

  ngOnInit(): void {
    this._hubSpotService.destroy()
    // setTimeout(() => {
      this._hubSpotService.init()
    // }, 1000)

  }

  ngOnDestroy() {
    this._hubSpotService.destroy()
  }

  onClose() {
    console.log()
    this.dialogRef.close()
  }
}
