import { Component, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/_reducers';
import { DeleteCardRequested } from 'src/app/core/_reducers/_actions/user.actions';

@Component({
  selector: 'app-dialog-delete-confirmation',
  templateUrl: './dialog-delete-confirmation.component.html',
  styleUrls: ['./dialog-delete-confirmation.component.scss']
})
export class DialogDeleteConfirmationComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogDeleteConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>,
  ){ }


  deleteCard(){
    this.store.dispatch(new DeleteCardRequested({card_id: this.data.cardId}))
  }

}
