export class SurveyModel {
    id: string
    user_id: string
    order_id: string
    store_id: string
    site_id: string
    organization_id: string
    qualification: number
    comments: string
    tags: string[]

    constructor(obj: any = null) {
      this.id = obj?.id
      this.user_id = obj?.user_id
      this.order_id = obj?.order_id
      this.store_id = obj?.store_id
      this.site_id= obj?.site_id
      this.organization_id= obj?.organization_id
      this.qualification= obj?.qualification
      this.comments= obj?.comments
      this.tags= obj?.tags
    }
  }
